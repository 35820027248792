

export const MoviesApi = {
  baseUrl: 'https://api.nomoreparties.co'
};

export const MainApi = {
  baseUrl: 'https://api.diploma-movies-dkim.nomoredomainsrocks.ru'
};

export const EmailValidationRegexp = '[a-zA-Z0-9_.]+@[a-zA-Z0-9_]+\\.[a-z]{2,}';

export const UserAgent = {
  desktop: {
    resolution: 1024,
    resolutionForLoad: 1139,
    movies: 12,
    more: 3
  },
  tablet: {
    resolution: 768,
    resolutionForLoad: 738,
    movies: 8,
    more: 2
  },
  mobile: {
    resolution: 480,
    movies: 5,
    more: 2
  }
};

export const ShortMovieDuration = 40;
import React from 'react';
import './AboutMe.css';
import SectionTitle from '../SectionTitle/SectionTitle';
import img from '../../../images/Danya.gif';
import { Link } from 'react-router-dom';

const AboutMe = () => {
    return (
        <section
            className='aboutme'
        >
            <SectionTitle title='Студент' />
            <section className='aboutme__content'>
                <section className='aboutme__info'>
                    <h3 className='aboutme__name'>Даниил</h3>
                    <p className='aboutme__position'>Картофельный человек, 24 лет</p>
                    <p className='aboutme__description'>
                        Я очень люблю сжигать дедлайны и кушать мармелад.
                        <br/>
                        Никому не советую так делать, особенно второе.
                        Отдавайте его лучше мне.
                        <br/>
                        Если ты читаешь это, значит всё будет хорошо ❤
                    </p>
                    <Link
                        className='aboutme__link'
                        to='https://github.com/danya-kim99'
                        target='_blank'
                    >
                        Github
                    </Link>
                </section>
                <img
                    src={img}
                    alt='Даниил'
                    className='aboutme__photo'
                />
            </section>
        </section>
    );
};

export default AboutMe;